// from incluso, to escluso

export default [
  {
    "code": "Promo CHOCOMAGNETICO",
    "percent": 15,
    "categories": ["EC81205"],
    "minProducts": 3,
    "from": "2025-02-03",
    "to": "2025-02-17"
  },
  {
    "code": "Promo Integratori",
    "percent": 20,
    "categories": ["EC71600"],
    "minProducts": 6,
    "from": "2025-02-10",
    "to": "2025-02-17"
  },
  {
    "code": "Promo Caffè",
    "percent": 20,
    "categories": ["EC81000"],
    "minProducts": 3,
    "from": "2025-02-19",
    "to": "2025-03-10"
  },
  {
    "code": "Promo Festa della Donna",
    "percent": 15,
    "categories": ["EC72800"],
    "minProducts": 2,
    "from": "2025-02-19",
    "to": "2025-03-10"
  },
]

